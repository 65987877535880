// Hero Section
.hero-section {
    // background: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5));
    background-image: linear-gradient(rgba(0, 0, 0, 0.231), rgba(0, 0, 0, 0.258)), url("../../images/banner2.jfif");
    // background-color: blue;
    // background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    color: var(--primary-text);

    .hero-flexContainer {
        display: flex;
        column-gap: 20px;
        padding: 75px 100px;
        width: 80%;
        margin: 0 auto;

        @media (max-width: 950px) {
            flex-direction: column;
        }
    }

    .hero-text {
        // text-align: center;
        i {
            position: relative;
            top: -18px;
        }
        // font-weight: bold;
    }
}
.hero-socials {
    .hero-profile {
        background-image: url("../../images/profile.png");
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        width: 100%;
        height: 175px;
        border-radius: 10px;

        @media (max-width: 950px) {
            width: 100%;
            height: 150px;
        }
    }
    .hero-icons {
        display: flex;
        gap: 14px;
        margin: 0 auto;
        a {
            color: white;
            font-size: 25px;

            &:hover {
                color: rgb(174, 174, 174);
            }
        }
    }
}

section {
    padding: 25px 100px;
    width: 80%;
    margin: 0 auto;

    &.flex {
        display: flex;
        column-gap: 30px;

        @media (max-width: 1050px) {
            flex-direction: column;
        }
    }

    h2 {
        padding-bottom: 10px;
    }

    .experienceRow,
    .educationRow {
        width: 50%;

        @media (max-width: 900px) {
            width: 100%;
        }

        .experienceItem {
            display: flex;
            column-gap: 10px;
            border-bottom: 1px solid rgba(0, 0, 0, 0.288);
            margin-bottom: 20px;
            padding-bottom: 10px;

            small {
                font-weight: 200 !important;
                font-size: 16px !important;
            }

            h4,
            h5,
            h6,
            p {
                margin-top: -10px;
            }

            .experienceImg {
                width: 50px;
                height: 50px;
            }
        }
    }
}

// Portfolio Section
.portfolioSection {
    .portfolioSection {
        width: 100%;
    }
    .portfolioFilter {
        display: flex;
        gap: 10px;
        margin: 0 auto;
        justify-content: center;
    }

    .porfolioContainerFlex {
        display: flex;

        align-items: center;

        .leftBtn {
            right: 50px;
            position: relative;
        }

        .rightBtn {
            left: 50px;
            position: relative;
        }
    }

    .porfolioContainer {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        gap: 25px;
        text-align: center;
        margin-top: 50px;

        @media (max-width: 900px) {
            display: flex;
            flex-direction: column;
        }

        .portfolioItem {
            width: 320px;
            height: 400px;
            background-color: rgb(209, 209, 209);
            box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
            // box-shadow: blue 0px 0px 0px 2px inset, rgb(255, 255, 255) 10px -10px 0px -3px, rgb(31, 193, 27) 10px -10px, rgb(255, 255, 255) 20px -20px 0px -3px, rgb(255, 217, 19) 20px -20px, rgb(255, 255, 255) 30px -30px 0px -3px, rgb(255, 156, 85) 30px -30px, rgb(255, 255, 255) 40px -40px 0px -3px, rgb(255, 85, 85) 40px -40px;
            img {
                width: 100%;
                height: 60%;
            }
            transition: all 0.2s ease-in-out;
            &:hover {
                transform: scale(1.1);
                cursor: pointer;
            }
        }
    }
}

// Contact Section
.contactSection {
    form {
        margin: 0 auto;
        width: 100%;
    }
}
