/** Scss stylesheet file with general style properties */

body {
  font-size: 13.8px;
  font-weight: 400;
  padding: 0;
  margin: 0;
  line-height: 1.614;
  background: #fff;
}

* {
  font-family: Arial, Helvetica, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-rendering: optimizeLegibility;
}

// h1,
// h2,
// h3,
// h4,
// h5,
// h6 {
//   padding: 0;
//   margin: 0 0 8px;
//   font-family: "Inter", sans-serif;
//   font-weight: 600;
// }

p {
  font-size: 14.4px;
  padding: 0;
  margin: 0 0 8px;

  b {
    font-weight: 600;
  }
}



.rounded {
  border-radius: 5px;
}

@mixin limited-line {
  display: -webkit-box !important;
  -webkit-box-orient: vertical !important;
  overflow: hidden !important;
}

.limited-line {
  &-1 {
    @include limited-line;
    -webkit-line-clamp: 1;
  }

  &-2 {
    @include limited-line;
    -webkit-line-clamp: 2;
  }
  &-3 {
    @include limited-line;
    -webkit-line-clamp: 3;
  }
}


a {
  text-decoration: none;
  color: var(--link-color);
  font-weight: bold;

  &:hover{
    color:rgb(180, 175, 249);
    font-weight: bold;
  }
}

.text-center {
  text-align: center;
}

// Center Div
.centerDiv {
  margin: 0 auto;
  width: 50%;
}

// Custom horizontal Line
.horizontal-line {
  margin: 0 auto;
  width: 90%;
  height: 1px;
  background-color: rgba(0, 0, 0, 0.174);
}