:root, [data-theme="default"]{
  --dark-primary: #282833;

  --purple: #8c0fb4;
  --violet: rgb(71, 13, 108);
  --red: #ff006a;
  --pink: #bb5b97;
  --orange: #ff9016;
  --yellow: #ffc927;
  --green: #1d8b3e;
  --olive: #696e5f;
  --teal: #008080;
  --blue: #3189ec;
  --contrast-blue: #3f70ee;
  --dark-blue: #083e5d;
  --black: #000;
  --brown: #452718;
  --white: #fff;
  --light: #f5f5f5;
  --grey: #909090;
  --primary-transparent: rgba(13, 78, 108, 0.04);
  --primary: #3189ec; //color.primary
  --secondary: rgb(71, 13, 108); //color.secondary
  --link-color: rgb(211, 208, 255);
  // --link-color: red;
  --primary-text:aliceblue;
}

// Background Colors
.bg-transparent {
  background: transparent !important;
}

.bg-primary {
  background: var(--primary) !important;

  &-opacity {
    background: rgba(var(--primary), 0.08) !important;
  }
}

.bg-dark-primary {
  background: var(--dark-primary) !important;

  &-opacity {
    background: rgba(var(--dark-primary), 0.08) !important;
  }
}

.bg-secondary {
  background: var(--secondary) !important;

  &-opacity {
    background: rgba(var(--secondary), 0.08) !important;
  }
}

.bg-blue {
  background: var(--blue) !important;

  &-opacity {
    background: rgba(var(--blue), 0.08) !important;
  }
}

.bg-contrast-blue {
  background: var(--contrast-blue) !important;

  &-opacity {
    background: rgba(var(--contrast-blue), 0.08) !important;
  }
}
.bg-dark-blue {
  background: var(--dark-blue) !important;

  &-opacity {
    background: rgba(var(--dark-blue), 0.08) !important;
  }
}
.bg-violet {
  background: var(--violet) !important;

  &-opacity {
    background: rgba(var(--violet), 0.08) !important;
  }
}
.bg-grey {
  background: var(--grey) !important;
  &-opacity {
    background: rgba(var(--grey), 0.08) !important;
  }
  &-btn {
    background: rgba(var(--grey), 0.18) !important;
  }
}
.bg-black {
  background: var(--black) !important;
  &-opacity {
    background: rgba(var(--black), 0.08) !important;
  }
}
.bg-light { background: var(--light) !important;}
.bg-white { background: var(--white) !important;}


// Font Colors
.fc-primary { color: var(--primary) !important;}
.fc-dark-primary { color: var(--dark-primary) !important; }
.fc-secondary { color: var(--secondary) !important;}
.fc-teal { color: var(--teal) !important;}
.fc-olive { color: var(--olive) !important;}
.fc-violet { color: var(--violet) !important;}
// Basic Colors
.fc-blue {
  color: var(--blue) !important;
  &-contrast {
    color: var(--contrast-blue);
  }
}
.fc-red { color: var(--red) !important; }
.fc-purple { color: var(--purple) !important;}
.fc-pink { color: var(--pink) !important;}
.fc-brown { color: var(--black) !important;}
.fc-grey { color: var(--grey) !important;}
.fc-black { color: var(--black) !important;}
.fc-light { color: var(--light) !important;}
.fc-white { color: var(--white) !important;}
.fc-green { color: var(--green) !important;}
.fc-orange { color: var(--orange) !important;}
.fc-yellow { color: var(--yellow) !important;}
